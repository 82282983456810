import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";

const AuthContext = createContext();

const initialState = {
  accessToken: window.sessionStorage.getItem("accessToken"),
  sessionId: window.sessionStorage.getItem("sessionId"),
  roleConfig: JSON.parse(window.sessionStorage.getItem("roleConfig")),
  user: JSON.parse(window.sessionStorage.getItem("user"))
};

function authReducer(state, action) {
  switch (action.type) {
    case "onRoleSwitch":
    case "onLogin": {
      const { accessToken, refreshToken, role } = action.payload;
      window.sessionStorage.setItem("accessToken", accessToken);
      window.sessionStorage.setItem("sessionId", refreshToken);
      window.sessionStorage.setItem("roleConfig", JSON.stringify(role));
      window.sessionStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        accessToken,
        sessionId: refreshToken,
        roleConfig: role,
        user: action.payload
      };
    }
    case "onLogout": {
      window.sessionStorage.clear();
      return {
        accessToken: null,
        sessionId: null,
        roleConfig: null,
        user: null
      };
    }
    default:
      throw new Error("Invalid action type");
  }
}

export default function AuthContextProvider({ children }) {
  const [authConfig, dispatch] = useReducer(authReducer, initialState);

  const parsedRoleConfig = useMemo(() => {
    if (authConfig?.roleConfig) {
      const { features } = authConfig.roleConfig;
      return features.reduce((accumulator, feature) => {
        const permissions = feature.permissionTypes.map((type) => type.name);
        const featureName = feature.name;
        return { ...accumulator, [featureName]: permissions };
      }, {});
    }
    return null;
  }, [authConfig]);

  const authContext = useMemo(
    () => ({
      authConfig,
      parsedRoleConfig,
      dispatch
    }),
    [authConfig, parsedRoleConfig]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);
