const urls = {
  PROD: "https://api.valuesale.in/",
  LOCAL: "http://localhost:8081/"
};

const baseURL = urls[process.env.REACT_APP_URL_ENV] ?? urls.PROD;
const urlDirect = process.env.REACT_APP_URL_ENV || "LOCAL";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const loginURL = `${baseURL}api/auth/login`;
const allUserListURL = `${baseURL}api/v1/user/all-users`;
const allUserStatusURL = `${baseURL}api/v1/user/all-user-statuses`;
const userProfile = `${baseURL}api/v1/user/profile`;
const userAllRole = `${baseURL}api/v1/user/all-roles`;
const allStates = `${baseURL}api/v1/utils/states`;
const allDistricts = `${baseURL}api/v1/utils/districts`;
const updateUserProfile = `${baseURL}api/v1/user/update-profile`;
const createUser = `${baseURL}api/v1/user/create`;
const sendErpCredentials = `${baseURL}api/v1/erp-credentials/persist`;
const getErpCredentials = `${baseURL}api/v1/erp-credentials`;
const getRepresentativeById = `${baseURL}api/v1/representative/list`;
const userListById = `${baseURL}api/v1/user/list`;
const associateRepresentative = `${baseURL}api/v1/representative/associate`;
const settingsFetch = `${baseURL}api/v1/settings/fetch`;
const settingsUpdate = `${baseURL}api/v1/settings/update`;
const notifyRetailer = `${baseURL}api/v1/settings/notify`;
const userGroups = `${baseURL}api/v1/userGroup/all`;
const userGroupsList = `${baseURL}api/v1/userGroup/list`;
const userGroupURL = `${baseURL}api/v1/userGroup/`;
const persistUserGroup = `${baseURL}api/v1/userGroup/persist`;
const getErpList = `${baseURL}api/v1/erp-credentials/supported-erp`;
const refreshMarg = `${baseURL}api/marg/masters/refresh-marg-masters`;
const clearMarg = `${baseURL}api/marg/masters/clear-marg-masters`;
const refreshDB = `${baseURL}api/v1/utils/refresh-db`;
const uploadDrugLicense = `${baseURL}api/v1/user/drug-license`;
const cfaIngestion = `${baseURL}api/v1/sap/process-masters`;
const inventoryProducts = `${baseURL}api/v1/inventory/admin/search/`;
const inventoryList = `${baseURL}api/v1/admin/inventory/grid`;
const saveInventoryChanges = `${baseURL}api/v1/admin/inventory/save`;
const inventoryTrigger = `${baseURL}api/v1/admin/inventory/group`;
const inventoryCombine = `${baseURL}api/v1/admin/inventory/combine`;

const APIConstants = {
  defaultHeaders,
  baseURL,
  loginURL,
  allUserListURL,
  allUserStatusURL,
  userProfile,
  userAllRole,
  updateUserProfile,
  allStates,
  allDistricts,
  createUser,
  sendErpCredentials,
  getErpCredentials,
  getRepresentativeById,
  userListById,
  urlDirect,
  settingsFetch,
  settingsUpdate,
  associateRepresentative,
  userGroups,
  userGroupURL,
  userGroupsList,
  persistUserGroup,
  getErpList,
  refreshMarg,
  clearMarg,
  refreshDB,
  uploadDrugLicense,
  notifyRetailer,
  cfaIngestion,
  inventoryProducts,
  inventoryList,
  saveInventoryChanges,
  inventoryTrigger,
  inventoryCombine
};

export default APIConstants;
